input[type="text"],
input[type="email"],
input[type="url"],
input[type="password"],
input[type="search"],
input[type="number"],
input[type="tel"],
input[type="range"],
input[type="date"],
input[type="month"],
input[type="week"],
input[type="time"],
input[type="datetime"],
input[type="datetime-local"],
input[type="color"],
textarea {
	color: $color__text-input;
	border: 1px solid $color__border-input;
	border-radius: 3px;
	font-family: $font__main;
	margin: 0;
	max-width: 100%;

	&:focus {
		color: $color__text-input-focus;
	}
}

select {
	border: 1px solid $color__border-input;
	color: inherit;
	font-family: $font__main;
	margin: 0;
	text-transform: none;
	max-width: 100%;
}

input[type="text"],
input[type="email"],
input[type="url"],
input[type="password"],
input[type="search"],
input[type="number"],
input[type="tel"],
input[type="range"],
input[type="date"],
input[type="month"],
input[type="week"],
input[type="time"],
input[type="datetime"],
input[type="datetime-local"],
input[type="color"] {
	padding: 5px;
	line-height: normal;

	&::-moz-focus-inner {
		border: 0;
		padding: 0;
	}

	&[disabled] {
		cursor: default;
	}

	&[type="checkbox"],
	&[type="radio"] {
		box-sizing: border-box;
		padding: 0;
	}

	&[type="number"] {
		&::-webkit-inner-spin-button,
		&::-webkit-outer-spin-button {
			height: auto;
		}
	}

	&[type="search"] {
		&::-webkit-search-cancel-button,
		&:-webkit-search-decoration {
			-webkit-appearance: none;
		}
	}
}

textarea {
	overflow: auto;
	padding-left: 3px;
	width: 100%;
}

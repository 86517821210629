.site {
	margin: 0 auto;
	overflow: hidden;
	padding: 0 $size__site-padding;
	width: 75%;
	position: relative;
	.site-header {
		clear: both;
		display: inline-block;
		width: $size__site-full;
	}
	.site-content {
		.content-container {
			@include flex;
			.content-area {
				display: inline-block;
				width: $size__site-full - $size__site-sidebar;

				.site-main {
					margin: 0;

					&.archive {
						.post {
							.entry-container {
								padding: 0 15px 80px 15px !important;
							}
						}
					}
					$colors: $color__1, $color__2 , $color__3 , $color__4;
					.page {
						background-color: $color__background-screen;
						padding: 15px 15px 40px 15px;
					}
					@for $i from 1 through length($colors) {
						.post {
							width: 100%;
							&:nth-child(#{length($colors)}n+#{$i}) {
								background-color: $color__background-screen;
								border-top: 8px solid nth($colors, $i);
								display: inline-block;
								position: relative;
								margin-bottom: $size__site-margin;
								.entry-title {
									a {
									  color: nth($colors, $i);
									}
								}
								.entry-container {
								  padding: 0 15px 120px 15px;
									@include respond-to($to-width: 800px) {
										padding: 0 15px 80px 15px;
									}
								  .entry-header {
								   	.entry-title {
								   		text-align: right;
								   		padding-right: 63px;
											@include respond-to($to-width: 800px) {
												text-align: left;
												font-size: 1.3rem;
											}
								   	}
								  }
									.entry-content {
										padding: 1rem .5rem;
									}
								}
								.entry-image {
									img {
										width: 100%;
										max-height: 20rem;
										overflow: hidden;
									}
								}
								.entry-image {
									margin-bottom: 20px;
								}
								.entry-excerpt {
									margin-bottom: 20px;
									p {
										max-height: 6.2rem;
										overflow: hidden;
										text-overflow: ellipsis;
										padding: 0;
										margin: 0;
										&::first-letter {
											font-size: 200%;
											color: nth($colors, $i);
										}
									}
								}
								@include respond-to($to-width: 800px) {
									width: 100%;
								}
								.entry-format {
									display: table;
									right: 0;
									position: absolute;
									top: 0;
									h2 {
										@include star(50px,80px, nth($colors, $i));
										display: table-cell;
										&::after {
											color: $color__text-format;
											top: 0;
											position: absolute;
											right: 0;
											left: 0;
											margin: auto;
										}
									}
								}
								.entry-footer {
									display: inline-block;
									position: absolute;
									background-color: $color__border-widget;
									bottom: 15px;
									left: -10px;
									width: 100%;
									font-size: .9rem;
									padding: .4rem 0 .4rem 20px;
									&::before {
										content: "";
										height: 100%;
										width: 10px;
										transform: skewY(43deg);
										background-color: darken($color__border-widget, 10%);
										position: absolute;
								    z-index: -1;
								    top: 5px;
								    left: 0;
									}
									&::after {
										content: "";
										position: absolute;
										width: 0;
										height: 0;
										border-top: 20px solid #fff;
										border-right: 20px solid transparent;
										right: -10px;
										bottom: -10px;
										transform: rotate(-45deg);
									}
									span[class$="-links"] {
										margin-right: 15px;
									}
								}
							}
						}
					}
				}
				@include respond-to($to-width: 800px) {
					width: 100%;
				}
			}

			.widget-area {
				display: inline-block;
				overflow: hidden;
				width: $size__site-sidebar;
				padding: 0 0 0 $size__site-margin;
				@include respond-to($to-width: 800px) {
					width: 100%;
					padding: 0;
				}
			}
		}
	}

	.site-footer {
		clear: both;
		display: inline-block;
		width: $size__site-full;
	}
	.page-title {
		margin-top: 0;
		color: $color__text-format;
	}
	@include respond-to($to-width: 800px) {
		width: 98%;
		margin-top: 0;
	}
}

a {
	@include transition(.3);
	background-color: transparent;
	color: $color__link;
	text-decoration: none;
	word-break: break-all;
	word-wrap: break-word;

	&:active,
	&:focus,
	&:hover {
		color: $color__link-hover;
	}

	&:focus {
		outline: 0;
	}

	&:active,
	&:hover {
		outline: 0;
	}
}

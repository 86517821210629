.crumbs {
	display: inline-block;
	font-size: .9rem;
	margin: 1rem 0 2rem;
	width: 100%;
	color: $color__text-format;
	a {
		color: $color__text-format;
	}
}

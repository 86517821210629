.site-footer {
	.footer-widgets {
		display: flex;
	}
	.site-info {
		text-align: left;
	}
	.textwidget {
		width: 100%;
		padding: 10px;
		color: $color__text-format;
	}
}

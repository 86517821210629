.widget-area {
.widget-related-post {
	li {
		display: inline-block;
		margin-bottom: 15px;
		img {
			float: left;
			height: 50px;
			margin-right: 5px;
			width: 50px;
		}
	}
}
.widget_search {
	label {
		display: block;
		width: 100%;
	}
	input[type='search'] {
		width: 100%;
	}
	}
	.widget_tag_cloud {
		a {
			padding: 2px;
			border: 1px solid lighten($color__background-body, 10%);
			border-radius: 5px;
			white-space: nowrap;
			line-height: 2;
		}
	}
}

// Background
$color__background-body: #556270;
$color__background-screen: #fff;
$color__background-hr: #ccc;
$color__background-button: #00AAA0;
$color__background-pre: #eee;
$color__background-ins: #fff9c0;
$color__background-warning: #ff0;
$color__backgorund-page: #DEE7EB;
// Text
$color__text-screen: #21759b;
$color__text-input: #666;
$color__text-button: #c7e7dc;
$color__text-input-focus: #111;
$color__link: #333;
$color__link-visited: purple;
$color__link-hover: #00AAA0;
$color__text-main: #333;
$color__text-warning: #f00;
$color__text-format: #fff;

// Border
$color__border-button: #00AAA0;
$color__border-button-hover: #ccc #bbb #aaa;
$color__border-button-focus: #aaa #bbb #bbb;
$color__border-input: #ccc;
$color__border-abbr: #666;
$color__border-body: #ccc;
$color__border-widget: #F8DEBD;

// Shadow
$color__shadow-focus: rgba(0, 0, 0, .6);

$color__1: #3b60e0;
$color__2: #FFB85F;
$color__3: #00AAA0;
$color__4: #7B8D8E;

.pagetop {
  bottom: 40px;
  height: 30px;
  position: absolute;
  right: -84px;
  transform: rotate(-48deg);
  width: 12rem;
  height: 5rem;
  a {
  	display: block;
  	height: 100%;
  	text-align: center;
    color: white;
    @include star(60px,60px,$color__2);
    &::before {
      z-index: -1;
    }
    i {
      display: table;
      font-size: 2rem;
      height: 100%;
      transform: rotate(43deg) translateX(4px);
      width: 100%;
      &::before {
        display: table-cell;
        vertical-align: middle;
      }
    }
  }
}

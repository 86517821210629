button,
.button-read-more,
input[type="button"],
input[type="reset"],
input[type="submit"] {
	@include font-size(1);
	-webkit-appearance: button;
	border: 1px solid;
	border-color: $color__border-button;
	border-radius: 3px;
	background: $color__background-button;
	cursor: pointer;
	color: $color__text-button;
	line-height: 1;
	padding: .3rem 1rem .4rem;
	overflow: visible;
	text-transform: none;

	&:active,
	&:focus {
		border-color: $color__border-button-focus;
		box-shadow: inset 0 -1px 0 rgba(255, 255, 255, .5), inset 0 2px 5px rgba(0, 0, 0, .15);
	}

	&::-moz-focus-inner {
		border: 0;
		padding: 0;
	}

	&[disabled] {
		cursor: default;
	}
}

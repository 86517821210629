// Rem output with px fallback
@mixin font-size($sizeValue: 1) {
	font-size: ($sizeValue * 14) * 1px;
	font-size: $sizeValue * 1rem;
}

// Center block
@mixin center-block {
	display: block;
	margin-left: auto;
	margin-right: auto;
}

// Clearfix
@mixin clearfix() {
	content: "";
	display: table;
	table-layout: fixed;
}

// Clear after (not all clearfix need this also)
@mixin clearfix-after() {
	clear: both;
}

// Transition
@mixin transition($duration: .3) {
	transition: all unquote($duration + "s") ease-in-out;
}

// Media Query
@mixin respond-to($from-width: 230px, $to-width: 1024px) {
	@media only screen and (min-width: $from-width) and (max-width: $to-width) {
		@content;
	}
}

@mixin flex() {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-flow: row wrap;
  flex-flow: row wrap;
}

@mixin star($width,$height,$color) {
	position: relative;
	text-align: center;
	vertical-align: middle;
	width: 40px;
	height: 25px;
	&::before {
		content: "";
		position: absolute;
	    color: $color__text-format;
		width: 0;
		height: 0;
		border-top: $width solid $color;
		border-left: $height solid transparent;
		right: 0;
		top: 0;
	}
}
@font-face {
  font-family: 'NotoSans';
  src: url('./src/font/NotoSansMonoCJKjp-Regular.otf');
}
$font__noto: 'NotoSans';

@font-face {
  font-family: 'Mgenplus';
  src: url('./src/font/rounded-mgenplus-2p-regular.ttf');
}
$font__mgenplus: 'Mgenplus';

$font__header: $font__mgenplus, sans-serif;
$font__main: $font__noto, sans-serif;
$font__code: Monaco, Consolas, "Andale Mono", "DejaVu Sans Mono", monospace;
$font__pre: "Courier 10 Pitch", Courier, monospace;
$font__line-height-body: 1.5;
$font__line-height-pre: .7;

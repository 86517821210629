.widget {
	margin: 0 0 $size__site-margin;
	display: inline-block;
	position: relative;
	width: 100%;
	background-color: $color__background-screen;
	padding: 0 20px 20px;
	border-top: 8px solid $color__border-widget;
	.widget-title {
	}
	ul {
		list-style: none;
		margin: 0;
		padding: 0 10px;
		li {
			margin: .4rem 0;
		}
	}

	table {
		width: 100%;
	}

	/* Make sure select elements fit in widgets. */
	select {
		margin: 1rem;
		max-width: 100%;
	}
}
.widget_search {
	padding-top: 20px;
	input[type="submit"] {
		display:  none;
	}
}

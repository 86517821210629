.site-header {
	clear: both;
	width: $size__site-full;
	padding: 0 $size__site-padding;
  color: $color__text-format;
	@include respond-to($to-width: 800px) {
		padding: 0;
	}
  a {
    color: $color__text-format;
  }
	.site-branding {
		text-align: center;
    width: 80%;
    margin: 0 auto;
		padding-top: 1rem;
		@include respond-to($to-width: 800px) {
			width: 100%;
		}
    .site-title {
      
    }
	}
	@include respond-to($to-width: 800px) {
		display: block;
	}
}
.about {
	margin: 20px auto;
	img {
		height: 15rem;
	}
    .about-description {
        background-color: $color__background-screen;
        border: 1px dashed $color__background-screen;
        text-align: center;
        width: 100%;
        padding: 10px 0;
        .about-title {
            position: relative;
        }
        .read-more {
					display: inline-block;
					-webkit-appearance: initial;
          position: relative;
        }
    }
}

table {
	border: 1px solid $color__border-input;
	border-collapse: collapse;
	border-spacing: 0;
	margin: 0 0 1.5rem;
	max-width: 100%;
	@include respond-to($to-width: 800px) {
		border: 0;
		display: inline-block;
		width: 100%;
	}
	&#wp-calendar {
		th, td {
			text-align: center;
		}
	}
	caption {
		margin: .4rem 0;
	}
	th, td {
		padding: .4rem;
		border: 1px solid $color__border-input;
		@include respond-to($to-width: 800px) {
			border: 0;
			display: inline-block;
		}
	}
	th {
		text-align: center;
	}
	td {
		&.has-a {
			padding: 0;

			a {
				display: inline-block;
				padding: .4rem;
			}
		}
		span {
			font-size: .8rem;
			padding: .2rem;
			color: $color__text-warning;
		}
	}
	thead, tbody, tfoot, tr {
		@include respond-to($to-width: 800px) {
			border: 0;
			display: inline-block;
		}
	}
}

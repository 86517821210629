html {
	box-sizing: border-box;
	font-family: sans-serif;
	font-size: 14px;
	-ms-text-size-adjust: 100%;
	-webkit-text-size-adjust: 100%;
}

*,
*::before,
*::after { /* Inherit box-sizing to make it easier to change the property for components that leverage other behavior; see http://css-tricks.com/inheriting-box-sizing-probably-slightly-better-best-practice/ */
	box-sizing: inherit;
}

body {
	background-color: $color__background-body;
	margin: 0;
	word-break: break-all;
}

body,
button,
input,
select,
textarea {
	@include font-size(1);
	color: $color__text-main;
	font-family: $font__main;
	line-height: $font__line-height-body;
}

article,
aside,
details,
figcaption,
figure,
footer,
header,
main,
menu,
nav,
section,
summary {
	display: block;
}

audio,
canvas,
progress,
video {
	display: inline-block;
	vertical-align: baseline;
}

audio {
	&:not([controls]) {
		display: none;
		height: 0;
	}
}

/* Make sure embeds and iframes fit their containers. */
embed,
iframe,
object {
	max-width: 100%;
}

h1,
h2,
h3,
h4,
h5,
h6 {
	clear: both;
}

h1 {
	font-size: 2rem;
	margin: .67rem 0;
}

h2 {
	font-size: 1.7rem;
	margin: .57rem 0;

}

h3 {
	font-size: 1.4rem;
	margin: .47rem 0;
}

h4 {
	font-size: 1.15rem;
	margin: .37rem 0;
}

h5 {
	font-size: .95rem;
	margin: .27rem 0;
}

h6 {
	font-size: .7rem;
	margin: .17rem 0;
}

[hidden],
template {
	display: none;
}

abbr {
	&[title] {
		border-bottom: 1px dotted;
	}
}

b,
strong {
	font-weight: bold;
}

blockquote,
q {
	margin: 0 1.5rem;
	quotes: "" "";

	&::before,
	&::after {
		content: "";
	}
}
blockquote {
	background-color: $color__background-pre;
	display: block;
	margin: 0 1.5em;
	padding: 2em 2em 1em 3em;
	position: relative;
	quotes: '" "';
	@include respond-to(handhelds) { margin: 0; width: 97%; }
	@include respond-to(medium-screens) { margin: 0; width: 98%; }

	&:before {
		font-size: 600%;
		content: '“';
		font-family: "ＭＳ　ゴシック";
		left: 8px;
		position: absolute;
		top: -20px;
	}

	&:after {
		content: '';
	}


}
hr {
	background-color: $color__background-hr;
	border: 0;
	box-sizing: content-box;
	height: 1px;
	margin-bottom: 1.5rem;
}

img {
	height: auto; /* Make sure images are scaled correctly. */
	max-width: 100%; /* Adhere to container width. */
	object-fit: cover;
	vertical-align: bottom;
}

mark {
	background: $color__background-warning;
	color: $color__text-input-focus;
}

p {
	margin-bottom: .5rem;
	margin-top: .5rem;
}

dfn,
cite,
em,
i {
	font-style: italic;
}

address {
	margin: 0 0 1.5rem;
}

pre {
	@include font-size(.9375);
	background: $color__background-pre;
	font-family: $font__pre;
	line-height: $font__line-height-pre;
	margin-bottom: 1.6rem;
	max-width: 100%;
	overflow: auto;
	padding: 1.6rem;
}

code,
kbd,
samp,
tt,
var {
	@include font-size(.9375);
	font-family: $font__code;
}

abbr,
acronym {
	border-bottom: 1px dotted $color__border-abbr;
	cursor: help;
}

mark,
ins {
	background: $color__background-ins;
	text-decoration: none;
}

big {
	font-size: 125%;
}

small {
	font-size: 80%;
}

sub,
sup {
	font-size: 75%;
	line-height: 0;
	position: relative;
	vertical-align: baseline;
}

sup {
	top: -.5rem;
}

sub {
	bottom: -.25rem;
}

svg {
	&:not(:root) {
		overflow: hidden;
	}
}

figure {
	display: inline-block;
	margin: 1rem;
	max-width: calc(100% - 2rem);
}

optgroup {
	color: inherit;
	font: inherit;
	font-weight: bold;
	margin: 0;
}

fieldset {
	border: 1px solid $color__border-input;
	margin: 0 2px;
	padding: .35rem .625rem .75rem;
}

legend {
	border: 0;
	padding: 0;
}

/** Class Nomalization **/
.sticky {
	display: block;
}

.byline,
.updated:not(.published) {
	display: none;
}

.single .byline,
.group-blog .byline {
	display: inline;
}

.page-links {
	clear: both;
	margin: 0 0 1.5rem;
}

/* Text meant only for screen readers. */
.screen-reader-text {
	clip: rect(1px, 1px, 1px, 1px);
	height: 1px;
	overflow: hidden;
	position: absolute !important;
	width: 1px;

	&:focus {
		@include font-size(.875);
		background-color: $color__background-screen;
		border-radius: 3px;
		box-shadow: 0 0 2px 2px $color__shadow-focus;
		clip: auto !important;
		color: $color__text-screen;
		display: block;
		font-weight: bold;
		height: auto;
		left: 5px;
		line-height: normal;
		padding: 15px 23px 14px;
		text-decoration: none;
		top: 5px;
		width: auto;
		z-index: 100000; /* Above WP toolbar. */
	}
}

/* Do not show the outline on the skip link target. */
.site-content {
	&[tabindex="-1"]:focus {
		outline: 0;
	}
}

.alignnone {
	margin-bottom: .5rem;
	margin-top: .5rem;
}

.alignleft {
	display: inline;
	float: left;
	margin-bottom: .5rem;
	margin-right: 1.5rem;
	margin-top: .5rem;
}

.alignright {
	display: inline;
	float: right;
	margin-bottom: .5em;
	margin-left: 1.5rem;
	margin-top: .5em;
}

.aligncenter {
	@include center-block;
	clear: both;
}

.clear::before,
.clear::after,
.entry-content::before,
.entry-content::after,
.comment-content::before,
.comment-content::after,
.site-header::before,
.site-header::after,
.site-content::before,
.site-content::after,
.site-footer::before,
.site-footer::after {
	@include clearfix;
}

.clear::after,
.entry-content::after,
.comment-content::after,
.site-header::after,
.site-content::after,
.site-footer::after {
	@include clearfix-after;
}

/* Globally hidden elements when Infinite Scroll is supported and in use. */
.infinite-scroll .posts-navigation, /* Older / Newer Posts Navigation (always hidden) */
.infinite-scroll.neverending .site-footer { /* Theme Footer (when set to scrolling) */
	display: none;
}

/* When Infinite Scroll has reached its end we need to re-display elements that were hidden (via .neverending) before. */
.infinity-end.neverending .site-footer {
	display: block;
}

.wp-caption {
	margin-bottom: 1.5rem;
	max-width: calc(100% - 2rem);
	width: auto !important;

	img {
		&[class*="wp-image-"] {
			@include center-block;
			max-width: calc(100% - 2rem);
		}
	}

	.wp-caption-text {
		margin: .8075rem 0;
	}
}

.wp-caption-text {
	text-align: center;
}

.gallery {
	margin-bottom: 1.5rem;
}

.gallery-item {
	display: inline-block;
	text-align: center;
	vertical-align: top;
	width: 100%;

	.gallery-columns-2 & {
		max-width: 50%;
	}

	.gallery-columns-3 & {
		max-width: 33.33%;
	}

	.gallery-columns-4 & {
		max-width: 25%;
	}

	.gallery-columns-5 & {
		max-width: 20%;
	}

	.gallery-columns-6 & {
		max-width: 16.66%;
	}

	.gallery-columns-7 & {
		max-width: 14.28%;
	}

	.gallery-columns-8 & {
		max-width: 12.5%;
	}

	.gallery-columns-9 & {
		max-width: 11.11%;
	}
}

.gallery-caption {
	display: block;
}
#wave {
  position: relative;
  height: 70px;
  width: 100%;
}
.standard {
	&::after {
		content:"\f15c";
		font-family: FontAwesome;
		font-size: 1.5rem;
		position: relative;
	}
}
.gallery,.image {
	&::after {
		content:"\f03e";
		font-family: FontAwesome;
		font-size: 1.5rem;
		position: relative;
	}
}
.audio {
	&::after {
		content:"\f001";
		font-family: FontAwesome;
		font-size: 1.5rem;
		position: relative;
	}
}
.video {
	&::after {
		content:"\f008";
		font-family: FontAwesome;
		font-size: 1.5rem;
		position: relative;
	}
}
.quote {
	&::after {
		content:"\f10d";
		font-family: FontAwesome;
		font-size: 1.5rem;
		position: relative;
	}
}
.link {
	&::after {
		content:"\f0c6";
		font-family: FontAwesome;
		font-size: 1.5rem;
		position: relative;
	}
}
.aside {
	&::after {
		content:"\f0c6";
		font-family: FontAwesome;
		font-size: 1.5rem;
		position: relative;
	}
}
.status {
	&::after {
		content:"\f24a";
		font-family: FontAwesome;
		font-size: 1.5rem;
		position: relative;
	}
}

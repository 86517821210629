.main-navigation {
	clear: both;
	display: block;
  text-align: center;
	width: 100%;
	ul {
		display: none;
		list-style: none;
		margin: 0;
		padding-left: 0;

		ul {
			display: block;
			box-shadow: 0 3px 3px rgba(0, 0, 0, .2);
			float: left;
			position: absolute;
			top: 2.5rem;
			left: -999rem;
			z-index: 99999;
			background-color: $color__background-screen;
			@include respond-to($to-width: 800px) {
				width: 100%;
			}

			ul {
				left: -999rem;
				top: 0;
			}

			li {
				border-bottom: none;
				text-align: left;
				border-bottom: 1px dashed $color__border-widget;
				a {
					color: $color__text-main;
				}
				&:hover > ul,
				&.focus > ul {
					left: 100%;
				}
			}

			a {
				width: 200px;
				@include respond-to($to-width: 800px) {
					width: 100%;
				}
			}

			:hover > a,
			.focus > a {
			}

			a:hover,
			a.focus {
			}
		}

		li:hover > ul,
		li.focus > ul {
			left: auto;
		}
	}
	li {
		float: left;
		position: relative;
		margin-right: 1rem;
		@include respond-to($to-width: 800px) {
			width: 100%;
		}

		&:hover > a,
		&.focus > a {
		}
	}
	a {
		display: block;
		text-decoration: none;
		padding: .5rem 1rem;
	}

	.current_page_item > a,
	.current-menu-item > a,
	.current_page_ancestor > a,
	.current-menu-ancestor > a {
	}
}

/* Small menu. */
.menu-toggle,
.main-navigation.toggled ul {
	display: block;
}

@media screen and (min-width: 37.5rem) {
	.menu-toggle {
		display: none;
	}
	.main-navigation ul {
		display: inline-flex;
		text-align: center;
		padding: 0;
		margin: auto;
	}
}

.comment-navigation,
.posts-navigation,
.post-navigation {

	.site-main {
		margin: 0 0 1.5rem;
		overflow: hidden;
	}

	.nav-previous {
		float: left;
		width: 50%;
	}

	.nav-next {
		float: right;
		text-align: right;
		width: 50%;
	}
}

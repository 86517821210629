.mw_wp_form {
	table {
		textarea {
			width: 100%;
		}
		input, {
			width: 90%;
		}
		td {
			@include respond-to($to-width: 800px) {
				display: block;
			}
		}
	}
}

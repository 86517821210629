/*!
Theme Name: SCM Subsidies 1.0
Theme URI: http://underscores.me/
Author: Underscores.me
Author URI: http://underscores.me/
Description:
Version: 1.0.0
License: GNU General Public License v2 or later
License URI: http://www.gnu.org/licenses/gpl-2.0.html
Text Domain: scm_subsidies
Tags:

This theme, like WordPress, is licensed under the GPL.
Use it to make something cool, have fun, and share what you've learned with others.

scm_subsidies is based on Underscores http://underscores.me/, (C) 2012-2016 Automattic, Inc.
Underscores is distributed under the terms of the GNU GPL v2 or later.

Normalizing styles have been helped along thanks to the fine work of
Nicolas Gallagher and Jonathan Neal http://necolas.github.com/normalize.css/
*/

/*--------------------------------------------------------------
>>> TABLE OF CONTENTS:
----------------------------------------------------------------
# Variable
  # Color
  # Structure
  # Typegraphy
# Mixin
# Normalize
# Element
  # Button
  # Field
  # Link
  # List
  # Table
# Component
  # Breadcrumb
  # Branding
  # Menu
  # Pagetop
  # Widget
# Layout
  # Archive
  # Content
  # Footer
  # Header
  # Sidebar
  # Single

--------------------------------------------------------------*/
@import "variable/variable";
@import "mixin/mixin";

/*--------------------------------------------------------------
# Normalize
--------------------------------------------------------------*/
@import "normalize";

/*--------------------------------------------------------------
# Elements
--------------------------------------------------------------*/
@import "element/element";

/*--------------------------------------------------------------
# Component
--------------------------------------------------------------*/
@import "component/component";

/*--------------------------------------------------------------
# Layout
--------------------------------------------------------------*/
@import "layout/layout";

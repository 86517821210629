.pagination {
	display: inline-block;
	vertical-align: baseline;
	width: 100%;
	text-align: center;
	margin: 20px 0;
	color: $color__text-format;
	.screen-reader-text {
		display: none;
	}
	a {
		padding: .4rem .6rem;
		text-decoration:none;
		transition: all .3s ease-in-out;
		color: $color__text-format;
		&:hover {
			opacity: .8;
		}
	}
	.dots {
		padding: .4rem .6rem;
	}
	.current {
		@include star(10px,10px,$color__2);
		border: 1px solid $color__2;
		color: $color__text-format;
		display: inline-block;
		&::before {
		    z-index: -1;
		}
	}
}

@charset "UTF-8";
/*!
Theme Name: SCM Subsidies 1.0
Theme URI: http://underscores.me/
Author: Underscores.me
Author URI: http://underscores.me/
Description:
Version: 1.0.0
License: GNU General Public License v2 or later
License URI: http://www.gnu.org/licenses/gpl-2.0.html
Text Domain: scm_subsidies
Tags:

This theme, like WordPress, is licensed under the GPL.
Use it to make something cool, have fun, and share what you've learned with others.

scm_subsidies is based on Underscores http://underscores.me/, (C) 2012-2016 Automattic, Inc.
Underscores is distributed under the terms of the GNU GPL v2 or later.

Normalizing styles have been helped along thanks to the fine work of
Nicolas Gallagher and Jonathan Neal http://necolas.github.com/normalize.css/
*/
/*--------------------------------------------------------------
>>> TABLE OF CONTENTS:
----------------------------------------------------------------
# Variable
  # Color
  # Structure
  # Typegraphy
# Mixin
# Normalize
# Element
  # Button
  # Field
  # Link
  # List
  # Table
# Component
  # Breadcrumb
  # Branding
  # Menu
  # Pagetop
  # Widget
# Layout
  # Archive
  # Content
  # Footer
  # Header
  # Sidebar
  # Single

--------------------------------------------------------------*/
/*--------------------------------------------------------------
## Color
--------------------------------------------------------------*/
/*--------------------------------------------------------------
## Typography
--------------------------------------------------------------*/
@font-face {
  font-family: 'NotoSans';
  src: url("./src/font/NotoSansMonoCJKjp-Regular.otf"); }

@font-face {
  font-family: 'Mgenplus';
  src: url("./src/font/rounded-mgenplus-2p-regular.ttf"); }

/*--------------------------------------------------------------
## Structure
--------------------------------------------------------------*/
/*--------------------------------------------------------------
# Normalize
--------------------------------------------------------------*/
html {
  box-sizing: border-box;
  font-family: sans-serif;
  font-size: 14px;
  -ms-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%; }

*,
*::before,
*::after {
  /* Inherit box-sizing to make it easier to change the property for components that leverage other behavior; see http://css-tricks.com/inheriting-box-sizing-probably-slightly-better-best-practice/ */
  box-sizing: inherit; }

body {
  background-color: #556270;
  margin: 0;
  word-break: break-all; }

body,
button,
input,
select,
textarea {
  font-size: 14px;
  font-size: 1rem;
  color: #333;
  font-family: "NotoSans", sans-serif;
  line-height: 1.5; }

article,
aside,
details,
figcaption,
figure,
footer,
header,
main,
menu,
nav,
section,
summary {
  display: block; }

audio,
canvas,
progress,
video {
  display: inline-block;
  vertical-align: baseline; }

audio:not([controls]) {
  display: none;
  height: 0; }

/* Make sure embeds and iframes fit their containers. */
embed,
iframe,
object {
  max-width: 100%; }

h1,
h2,
h3,
h4,
h5,
h6 {
  clear: both; }

h1 {
  font-size: 2rem;
  margin: .67rem 0; }

h2 {
  font-size: 1.7rem;
  margin: .57rem 0; }

h3 {
  font-size: 1.4rem;
  margin: .47rem 0; }

h4 {
  font-size: 1.15rem;
  margin: .37rem 0; }

h5 {
  font-size: .95rem;
  margin: .27rem 0; }

h6 {
  font-size: .7rem;
  margin: .17rem 0; }

[hidden],
template {
  display: none; }

abbr[title] {
  border-bottom: 1px dotted; }

b,
strong {
  font-weight: bold; }

blockquote,
q {
  margin: 0 1.5rem;
  quotes: "" ""; }
  blockquote::before, blockquote::after,
  q::before,
  q::after {
    content: ""; }

blockquote {
  background-color: #eee;
  display: block;
  margin: 0 1.5em;
  padding: 2em 2em 1em 3em;
  position: relative;
  quotes: '" "'; }
  @media only screen and (min-width: handhelds) and (max-width: 1024px) {
    blockquote {
      margin: 0;
      width: 97%; } }
  @media only screen and (min-width: medium-screens) and (max-width: 1024px) {
    blockquote {
      margin: 0;
      width: 98%; } }
  blockquote:before {
    font-size: 600%;
    content: '“';
    font-family: "ＭＳ　ゴシック";
    left: 8px;
    position: absolute;
    top: -20px; }
  blockquote:after {
    content: ''; }

hr {
  background-color: #ccc;
  border: 0;
  box-sizing: content-box;
  height: 1px;
  margin-bottom: 1.5rem; }

img {
  height: auto;
  /* Make sure images are scaled correctly. */
  max-width: 100%;
  /* Adhere to container width. */
  object-fit: cover;
  vertical-align: bottom; }

mark {
  background: #ff0;
  color: #111; }

p {
  margin-bottom: .5rem;
  margin-top: .5rem; }

dfn,
cite,
em,
i {
  font-style: italic; }

address {
  margin: 0 0 1.5rem; }

pre {
  font-size: 13.125px;
  font-size: 0.9375rem;
  background: #eee;
  font-family: "Courier 10 Pitch", Courier, monospace;
  line-height: 0.7;
  margin-bottom: 1.6rem;
  max-width: 100%;
  overflow: auto;
  padding: 1.6rem; }

code,
kbd,
samp,
tt,
var {
  font-size: 13.125px;
  font-size: 0.9375rem;
  font-family: Monaco, Consolas, "Andale Mono", "DejaVu Sans Mono", monospace; }

abbr,
acronym {
  border-bottom: 1px dotted #666;
  cursor: help; }

mark,
ins {
  background: #fff9c0;
  text-decoration: none; }

big {
  font-size: 125%; }

small {
  font-size: 80%; }

sub,
sup {
  font-size: 75%;
  line-height: 0;
  position: relative;
  vertical-align: baseline; }

sup {
  top: -.5rem; }

sub {
  bottom: -.25rem; }

svg:not(:root) {
  overflow: hidden; }

figure {
  display: inline-block;
  margin: 1rem;
  max-width: calc(100% - 2rem); }

optgroup {
  color: inherit;
  font: inherit;
  font-weight: bold;
  margin: 0; }

fieldset {
  border: 1px solid #ccc;
  margin: 0 2px;
  padding: .35rem .625rem .75rem; }

legend {
  border: 0;
  padding: 0; }

/** Class Nomalization **/
.sticky {
  display: block; }

.byline,
.updated:not(.published) {
  display: none; }

.single .byline,
.group-blog .byline {
  display: inline; }

.page-links {
  clear: both;
  margin: 0 0 1.5rem; }

/* Text meant only for screen readers. */
.screen-reader-text {
  clip: rect(1px, 1px, 1px, 1px);
  height: 1px;
  overflow: hidden;
  position: absolute !important;
  width: 1px; }
  .screen-reader-text:focus {
    font-size: 12.25px;
    font-size: 0.875rem;
    background-color: #fff;
    border-radius: 3px;
    box-shadow: 0 0 2px 2px rgba(0, 0, 0, 0.6);
    clip: auto !important;
    color: #21759b;
    display: block;
    font-weight: bold;
    height: auto;
    left: 5px;
    line-height: normal;
    padding: 15px 23px 14px;
    text-decoration: none;
    top: 5px;
    width: auto;
    z-index: 100000;
    /* Above WP toolbar. */ }

/* Do not show the outline on the skip link target. */
.site-content[tabindex="-1"]:focus {
  outline: 0; }

.alignnone {
  margin-bottom: .5rem;
  margin-top: .5rem; }

.alignleft {
  display: inline;
  float: left;
  margin-bottom: .5rem;
  margin-right: 1.5rem;
  margin-top: .5rem; }

.alignright {
  display: inline;
  float: right;
  margin-bottom: .5em;
  margin-left: 1.5rem;
  margin-top: .5em; }

.aligncenter {
  display: block;
  margin-left: auto;
  margin-right: auto;
  clear: both; }

.clear::before,
.clear::after,
.entry-content::before,
.entry-content::after,
.comment-content::before,
.comment-content::after,
.site-header::before,
.site-header::after,
.site-content::before,
.site-content::after,
.site-footer::before,
.site-footer::after {
  content: "";
  display: table;
  table-layout: fixed; }

.clear::after,
.entry-content::after,
.comment-content::after,
.site-header::after,
.site-content::after,
.site-footer::after {
  clear: both; }

/* Globally hidden elements when Infinite Scroll is supported and in use. */
.infinite-scroll .posts-navigation,
.infinite-scroll.neverending .site-footer {
  /* Theme Footer (when set to scrolling) */
  display: none; }

/* When Infinite Scroll has reached its end we need to re-display elements that were hidden (via .neverending) before. */
.infinity-end.neverending .site-footer {
  display: block; }

.wp-caption {
  margin-bottom: 1.5rem;
  max-width: calc(100% - 2rem);
  width: auto !important; }
  .wp-caption img[class*="wp-image-"] {
    display: block;
    margin-left: auto;
    margin-right: auto;
    max-width: calc(100% - 2rem); }
  .wp-caption .wp-caption-text {
    margin: .8075rem 0; }

.wp-caption-text {
  text-align: center; }

.gallery {
  margin-bottom: 1.5rem; }

.gallery-item {
  display: inline-block;
  text-align: center;
  vertical-align: top;
  width: 100%; }
  .gallery-columns-2 .gallery-item {
    max-width: 50%; }
  .gallery-columns-3 .gallery-item {
    max-width: 33.33%; }
  .gallery-columns-4 .gallery-item {
    max-width: 25%; }
  .gallery-columns-5 .gallery-item {
    max-width: 20%; }
  .gallery-columns-6 .gallery-item {
    max-width: 16.66%; }
  .gallery-columns-7 .gallery-item {
    max-width: 14.28%; }
  .gallery-columns-8 .gallery-item {
    max-width: 12.5%; }
  .gallery-columns-9 .gallery-item {
    max-width: 11.11%; }

.gallery-caption {
  display: block; }

#wave {
  position: relative;
  height: 70px;
  width: 100%; }

.standard::after {
  content: "\f15c";
  font-family: FontAwesome;
  font-size: 1.5rem;
  position: relative; }

.gallery::after, .image::after {
  content: "\f03e";
  font-family: FontAwesome;
  font-size: 1.5rem;
  position: relative; }

.audio::after {
  content: "\f001";
  font-family: FontAwesome;
  font-size: 1.5rem;
  position: relative; }

.video::after {
  content: "\f008";
  font-family: FontAwesome;
  font-size: 1.5rem;
  position: relative; }

.quote::after {
  content: "\f10d";
  font-family: FontAwesome;
  font-size: 1.5rem;
  position: relative; }

.link::after {
  content: "\f0c6";
  font-family: FontAwesome;
  font-size: 1.5rem;
  position: relative; }

.aside::after {
  content: "\f0c6";
  font-family: FontAwesome;
  font-size: 1.5rem;
  position: relative; }

.status::after {
  content: "\f24a";
  font-family: FontAwesome;
  font-size: 1.5rem;
  position: relative; }

/*--------------------------------------------------------------
# Elements
--------------------------------------------------------------*/
/*--------------------------------------------------------------
## Button
--------------------------------------------------------------*/
button,
.button-read-more,
input[type="button"],
input[type="reset"],
input[type="submit"] {
  font-size: 14px;
  font-size: 1rem;
  -webkit-appearance: button;
  border: 1px solid;
  border-color: #00AAA0;
  border-radius: 3px;
  background: #00AAA0;
  cursor: pointer;
  color: #c7e7dc;
  line-height: 1;
  padding: .3rem 1rem .4rem;
  overflow: visible;
  text-transform: none; }
  button:active, button:focus,
  .button-read-more:active,
  .button-read-more:focus,
  input[type="button"]:active,
  input[type="button"]:focus,
  input[type="reset"]:active,
  input[type="reset"]:focus,
  input[type="submit"]:active,
  input[type="submit"]:focus {
    border-color: #aaa #bbb #bbb;
    box-shadow: inset 0 -1px 0 rgba(255, 255, 255, 0.5), inset 0 2px 5px rgba(0, 0, 0, 0.15); }
  button::-moz-focus-inner,
  .button-read-more::-moz-focus-inner,
  input[type="button"]::-moz-focus-inner,
  input[type="reset"]::-moz-focus-inner,
  input[type="submit"]::-moz-focus-inner {
    border: 0;
    padding: 0; }
  button[disabled],
  .button-read-more[disabled],
  input[type="button"][disabled],
  input[type="reset"][disabled],
  input[type="submit"][disabled] {
    cursor: default; }

/*--------------------------------------------------------------
## Field
--------------------------------------------------------------*/
input[type="text"],
input[type="email"],
input[type="url"],
input[type="password"],
input[type="search"],
input[type="number"],
input[type="tel"],
input[type="range"],
input[type="date"],
input[type="month"],
input[type="week"],
input[type="time"],
input[type="datetime"],
input[type="datetime-local"],
input[type="color"],
textarea {
  color: #666;
  border: 1px solid #ccc;
  border-radius: 3px;
  font-family: "NotoSans", sans-serif;
  margin: 0;
  max-width: 100%; }
  input[type="text"]:focus,
  input[type="email"]:focus,
  input[type="url"]:focus,
  input[type="password"]:focus,
  input[type="search"]:focus,
  input[type="number"]:focus,
  input[type="tel"]:focus,
  input[type="range"]:focus,
  input[type="date"]:focus,
  input[type="month"]:focus,
  input[type="week"]:focus,
  input[type="time"]:focus,
  input[type="datetime"]:focus,
  input[type="datetime-local"]:focus,
  input[type="color"]:focus,
  textarea:focus {
    color: #111; }

select {
  border: 1px solid #ccc;
  color: inherit;
  font-family: "NotoSans", sans-serif;
  margin: 0;
  text-transform: none;
  max-width: 100%; }

input[type="text"],
input[type="email"],
input[type="url"],
input[type="password"],
input[type="search"],
input[type="number"],
input[type="tel"],
input[type="range"],
input[type="date"],
input[type="month"],
input[type="week"],
input[type="time"],
input[type="datetime"],
input[type="datetime-local"],
input[type="color"] {
  padding: 5px;
  line-height: normal; }
  input[type="text"]::-moz-focus-inner,
  input[type="email"]::-moz-focus-inner,
  input[type="url"]::-moz-focus-inner,
  input[type="password"]::-moz-focus-inner,
  input[type="search"]::-moz-focus-inner,
  input[type="number"]::-moz-focus-inner,
  input[type="tel"]::-moz-focus-inner,
  input[type="range"]::-moz-focus-inner,
  input[type="date"]::-moz-focus-inner,
  input[type="month"]::-moz-focus-inner,
  input[type="week"]::-moz-focus-inner,
  input[type="time"]::-moz-focus-inner,
  input[type="datetime"]::-moz-focus-inner,
  input[type="datetime-local"]::-moz-focus-inner,
  input[type="color"]::-moz-focus-inner {
    border: 0;
    padding: 0; }
  input[type="text"][disabled],
  input[type="email"][disabled],
  input[type="url"][disabled],
  input[type="password"][disabled],
  input[type="search"][disabled],
  input[type="number"][disabled],
  input[type="tel"][disabled],
  input[type="range"][disabled],
  input[type="date"][disabled],
  input[type="month"][disabled],
  input[type="week"][disabled],
  input[type="time"][disabled],
  input[type="datetime"][disabled],
  input[type="datetime-local"][disabled],
  input[type="color"][disabled] {
    cursor: default; }
  input[type="text"][type="checkbox"], input[type="text"][type="radio"],
  input[type="email"][type="checkbox"],
  input[type="email"][type="radio"],
  input[type="url"][type="checkbox"],
  input[type="url"][type="radio"],
  input[type="password"][type="checkbox"],
  input[type="password"][type="radio"],
  input[type="search"][type="checkbox"],
  input[type="search"][type="radio"],
  input[type="number"][type="checkbox"],
  input[type="number"][type="radio"],
  input[type="tel"][type="checkbox"],
  input[type="tel"][type="radio"],
  input[type="range"][type="checkbox"],
  input[type="range"][type="radio"],
  input[type="date"][type="checkbox"],
  input[type="date"][type="radio"],
  input[type="month"][type="checkbox"],
  input[type="month"][type="radio"],
  input[type="week"][type="checkbox"],
  input[type="week"][type="radio"],
  input[type="time"][type="checkbox"],
  input[type="time"][type="radio"],
  input[type="datetime"][type="checkbox"],
  input[type="datetime"][type="radio"],
  input[type="datetime-local"][type="checkbox"],
  input[type="datetime-local"][type="radio"],
  input[type="color"][type="checkbox"],
  input[type="color"][type="radio"] {
    box-sizing: border-box;
    padding: 0; }
  input[type="text"][type="number"]::-webkit-inner-spin-button, input[type="text"][type="number"]::-webkit-outer-spin-button,
  input[type="email"][type="number"]::-webkit-inner-spin-button,
  input[type="email"][type="number"]::-webkit-outer-spin-button,
  input[type="url"][type="number"]::-webkit-inner-spin-button,
  input[type="url"][type="number"]::-webkit-outer-spin-button,
  input[type="password"][type="number"]::-webkit-inner-spin-button,
  input[type="password"][type="number"]::-webkit-outer-spin-button,
  input[type="search"][type="number"]::-webkit-inner-spin-button,
  input[type="search"][type="number"]::-webkit-outer-spin-button,
  input[type="number"][type="number"]::-webkit-inner-spin-button,
  input[type="number"][type="number"]::-webkit-outer-spin-button,
  input[type="tel"][type="number"]::-webkit-inner-spin-button,
  input[type="tel"][type="number"]::-webkit-outer-spin-button,
  input[type="range"][type="number"]::-webkit-inner-spin-button,
  input[type="range"][type="number"]::-webkit-outer-spin-button,
  input[type="date"][type="number"]::-webkit-inner-spin-button,
  input[type="date"][type="number"]::-webkit-outer-spin-button,
  input[type="month"][type="number"]::-webkit-inner-spin-button,
  input[type="month"][type="number"]::-webkit-outer-spin-button,
  input[type="week"][type="number"]::-webkit-inner-spin-button,
  input[type="week"][type="number"]::-webkit-outer-spin-button,
  input[type="time"][type="number"]::-webkit-inner-spin-button,
  input[type="time"][type="number"]::-webkit-outer-spin-button,
  input[type="datetime"][type="number"]::-webkit-inner-spin-button,
  input[type="datetime"][type="number"]::-webkit-outer-spin-button,
  input[type="datetime-local"][type="number"]::-webkit-inner-spin-button,
  input[type="datetime-local"][type="number"]::-webkit-outer-spin-button,
  input[type="color"][type="number"]::-webkit-inner-spin-button,
  input[type="color"][type="number"]::-webkit-outer-spin-button {
    height: auto; }
  input[type="text"][type="search"]::-webkit-search-cancel-button, input[type="text"][type="search"]:-webkit-search-decoration,
  input[type="email"][type="search"]::-webkit-search-cancel-button,
  input[type="email"][type="search"]:-webkit-search-decoration,
  input[type="url"][type="search"]::-webkit-search-cancel-button,
  input[type="url"][type="search"]:-webkit-search-decoration,
  input[type="password"][type="search"]::-webkit-search-cancel-button,
  input[type="password"][type="search"]:-webkit-search-decoration,
  input[type="search"][type="search"]::-webkit-search-cancel-button,
  input[type="search"][type="search"]:-webkit-search-decoration,
  input[type="number"][type="search"]::-webkit-search-cancel-button,
  input[type="number"][type="search"]:-webkit-search-decoration,
  input[type="tel"][type="search"]::-webkit-search-cancel-button,
  input[type="tel"][type="search"]:-webkit-search-decoration,
  input[type="range"][type="search"]::-webkit-search-cancel-button,
  input[type="range"][type="search"]:-webkit-search-decoration,
  input[type="date"][type="search"]::-webkit-search-cancel-button,
  input[type="date"][type="search"]:-webkit-search-decoration,
  input[type="month"][type="search"]::-webkit-search-cancel-button,
  input[type="month"][type="search"]:-webkit-search-decoration,
  input[type="week"][type="search"]::-webkit-search-cancel-button,
  input[type="week"][type="search"]:-webkit-search-decoration,
  input[type="time"][type="search"]::-webkit-search-cancel-button,
  input[type="time"][type="search"]:-webkit-search-decoration,
  input[type="datetime"][type="search"]::-webkit-search-cancel-button,
  input[type="datetime"][type="search"]:-webkit-search-decoration,
  input[type="datetime-local"][type="search"]::-webkit-search-cancel-button,
  input[type="datetime-local"][type="search"]:-webkit-search-decoration,
  input[type="color"][type="search"]::-webkit-search-cancel-button,
  input[type="color"][type="search"]:-webkit-search-decoration {
    -webkit-appearance: none; }

textarea {
  overflow: auto;
  padding-left: 3px;
  width: 100%; }

/*--------------------------------------------------------------
## Link
--------------------------------------------------------------*/
a {
  transition: all 0.3s ease-in-out;
  background-color: transparent;
  color: #333;
  text-decoration: none;
  word-break: break-all;
  word-wrap: break-word; }
  a:active, a:focus, a:hover {
    color: #00AAA0; }
  a:focus {
    outline: 0; }
  a:active, a:hover {
    outline: 0; }

/*--------------------------------------------------------------
## List
--------------------------------------------------------------*/
ul, ol {
  margin: 0 0 .5rem 1rem;
  padding-left: .8rem; }

ul {
  list-style: disc; }

ol {
  list-style: decimal; }

li > ul,
li > ol {
  margin-bottom: 0;
  margin-left: 0; }

dt {
  font-weight: bold; }

dd {
  margin: 0 1.5rem .5rem; }

/*--------------------------------------------------------------
## Table
--------------------------------------------------------------*/
table {
  border: 1px solid #ccc;
  border-collapse: collapse;
  border-spacing: 0;
  margin: 0 0 1.5rem;
  max-width: 100%; }
  @media only screen and (min-width: 230px) and (max-width: 800px) {
    table {
      border: 0;
      display: inline-block;
      width: 100%; } }
  table#wp-calendar th, table#wp-calendar td {
    text-align: center; }
  table caption {
    margin: .4rem 0; }
  table th, table td {
    padding: .4rem;
    border: 1px solid #ccc; }
    @media only screen and (min-width: 230px) and (max-width: 800px) {
      table th, table td {
        border: 0;
        display: inline-block; } }
  table th {
    text-align: center; }
  table td.has-a {
    padding: 0; }
    table td.has-a a {
      display: inline-block;
      padding: .4rem; }
  table td span {
    font-size: .8rem;
    padding: .2rem;
    color: #f00; }
  @media only screen and (min-width: 230px) and (max-width: 800px) {
    table thead, table tbody, table tfoot, table tr {
      border: 0;
      display: inline-block; } }

/*--------------------------------------------------------------
## form
--------------------------------------------------------------*/
.mw_wp_form table textarea {
  width: 100%; }

.mw_wp_form table input {
  width: 90%; }

@media only screen and (min-width: 230px) and (max-width: 800px) {
  .mw_wp_form table td {
    display: block; } }

/*--------------------------------------------------------------
# Component
--------------------------------------------------------------*/
/*--------------------------------------------------------------
## Breadcrumb
--------------------------------------------------------------*/
.crumbs {
  display: inline-block;
  font-size: .9rem;
  margin: 1rem 0 2rem;
  width: 100%;
  color: #fff; }
  .crumbs a {
    color: #fff; }

/*--------------------------------------------------------------
## Menu
--------------------------------------------------------------*/
.main-navigation {
  clear: both;
  display: block;
  text-align: center;
  width: 100%; }
  .main-navigation ul {
    display: none;
    list-style: none;
    margin: 0;
    padding-left: 0; }
    .main-navigation ul ul {
      display: block;
      box-shadow: 0 3px 3px rgba(0, 0, 0, 0.2);
      float: left;
      position: absolute;
      top: 2.5rem;
      left: -999rem;
      z-index: 99999;
      background-color: #fff; }
      @media only screen and (min-width: 230px) and (max-width: 800px) {
        .main-navigation ul ul {
          width: 100%; } }
      .main-navigation ul ul ul {
        left: -999rem;
        top: 0; }
      .main-navigation ul ul li {
        border-bottom: none;
        text-align: left;
        border-bottom: 1px dashed #F8DEBD; }
        .main-navigation ul ul li a {
          color: #333; }
        .main-navigation ul ul li:hover > ul,
        .main-navigation ul ul li.focus > ul {
          left: 100%; }
      .main-navigation ul ul a {
        width: 200px; }
        @media only screen and (min-width: 230px) and (max-width: 800px) {
          .main-navigation ul ul a {
            width: 100%; } }
    .main-navigation ul li:hover > ul,
    .main-navigation ul li.focus > ul {
      left: auto; }
  .main-navigation li {
    float: left;
    position: relative;
    margin-right: 1rem; }
    @media only screen and (min-width: 230px) and (max-width: 800px) {
      .main-navigation li {
        width: 100%; } }
  .main-navigation a {
    display: block;
    text-decoration: none;
    padding: .5rem 1rem; }

/* Small menu. */
.menu-toggle,
.main-navigation.toggled ul {
  display: block; }

@media screen and (min-width: 37.5rem) {
  .menu-toggle {
    display: none; }
  .main-navigation ul {
    display: inline-flex;
    text-align: center;
    padding: 0;
    margin: auto; } }

.comment-navigation .site-main,
.posts-navigation .site-main,
.post-navigation .site-main {
  margin: 0 0 1.5rem;
  overflow: hidden; }

.comment-navigation .nav-previous,
.posts-navigation .nav-previous,
.post-navigation .nav-previous {
  float: left;
  width: 50%; }

.comment-navigation .nav-next,
.posts-navigation .nav-next,
.post-navigation .nav-next {
  float: right;
  text-align: right;
  width: 50%; }

/*--------------------------------------------------------------
## Pagetop
--------------------------------------------------------------*/
.pagetop {
  bottom: 40px;
  height: 30px;
  position: absolute;
  right: -84px;
  transform: rotate(-48deg);
  width: 12rem;
  height: 5rem; }
  .pagetop a {
    display: block;
    height: 100%;
    text-align: center;
    color: white;
    position: relative;
    text-align: center;
    vertical-align: middle;
    width: 40px;
    height: 25px; }
    .pagetop a::before {
      content: "";
      position: absolute;
      color: #fff;
      width: 0;
      height: 0;
      border-top: 60px solid #FFB85F;
      border-left: 60px solid transparent;
      right: 0;
      top: 0; }
    .pagetop a::before {
      z-index: -1; }
    .pagetop a i {
      display: table;
      font-size: 2rem;
      height: 100%;
      transform: rotate(43deg) translateX(4px);
      width: 100%; }
      .pagetop a i::before {
        display: table-cell;
        vertical-align: middle; }

/*--------------------------------------------------------------
## Widget
--------------------------------------------------------------*/
.widget {
  margin: 0 0 1.5rem;
  display: inline-block;
  position: relative;
  width: 100%;
  background-color: #fff;
  padding: 0 20px 20px;
  border-top: 8px solid #F8DEBD;
  /* Make sure select elements fit in widgets. */ }
  .widget ul {
    list-style: none;
    margin: 0;
    padding: 0 10px; }
    .widget ul li {
      margin: .4rem 0; }
  .widget table {
    width: 100%; }
  .widget select {
    margin: 1rem;
    max-width: 100%; }

.widget_search {
  padding-top: 20px; }
  .widget_search input[type="submit"] {
    display: none; }

/*--------------------------------------------------------------
## pagination
--------------------------------------------------------------*/
.pagination {
  display: inline-block;
  vertical-align: baseline;
  width: 100%;
  text-align: center;
  margin: 20px 0;
  color: #fff; }
  .pagination .screen-reader-text {
    display: none; }
  .pagination a {
    padding: .4rem .6rem;
    text-decoration: none;
    transition: all .3s ease-in-out;
    color: #fff; }
    .pagination a:hover {
      opacity: .8; }
  .pagination .dots {
    padding: .4rem .6rem; }
  .pagination .current {
    position: relative;
    text-align: center;
    vertical-align: middle;
    width: 40px;
    height: 25px;
    border: 1px solid #FFB85F;
    color: #fff;
    display: inline-block; }
    .pagination .current::before {
      content: "";
      position: absolute;
      color: #fff;
      width: 0;
      height: 0;
      border-top: 10px solid #FFB85F;
      border-left: 10px solid transparent;
      right: 0;
      top: 0; }
    .pagination .current::before {
      z-index: -1; }

/*--------------------------------------------------------------
# Layout
--------------------------------------------------------------*/
/*--------------------------------------------------------------
## Archive
--------------------------------------------------------------*/
/*--------------------------------------------------------------
## Content
--------------------------------------------------------------*/
.site {
  margin: 0 auto;
  overflow: hidden;
  padding: 0 5%;
  width: 75%;
  position: relative; }
  .site .site-header {
    clear: both;
    display: inline-block;
    width: 100%; }
  .site .site-content .content-container {
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-flow: row wrap;
    flex-flow: row wrap; }
    .site .site-content .content-container .content-area {
      display: inline-block;
      width: 65%; }
      .site .site-content .content-container .content-area .site-main {
        margin: 0; }
        .site .site-content .content-container .content-area .site-main.archive .post .entry-container {
          padding: 0 15px 80px 15px !important; }
        .site .site-content .content-container .content-area .site-main .page {
          background-color: #fff;
          padding: 15px 15px 40px 15px; }
        .site .site-content .content-container .content-area .site-main .post {
          width: 100%; }
          .site .site-content .content-container .content-area .site-main .post:nth-child(4n+1) {
            background-color: #fff;
            border-top: 8px solid #3b60e0;
            display: inline-block;
            position: relative;
            margin-bottom: 1.5rem; }
            .site .site-content .content-container .content-area .site-main .post:nth-child(4n+1) .entry-title a {
              color: #3b60e0; }
            .site .site-content .content-container .content-area .site-main .post:nth-child(4n+1) .entry-container {
              padding: 0 15px 120px 15px; }
              @media only screen and (min-width: 230px) and (max-width: 800px) {
                .site .site-content .content-container .content-area .site-main .post:nth-child(4n+1) .entry-container {
                  padding: 0 15px 80px 15px; } }
              .site .site-content .content-container .content-area .site-main .post:nth-child(4n+1) .entry-container .entry-header .entry-title {
                text-align: right;
                padding-right: 63px; }
                @media only screen and (min-width: 230px) and (max-width: 800px) {
                  .site .site-content .content-container .content-area .site-main .post:nth-child(4n+1) .entry-container .entry-header .entry-title {
                    text-align: left;
                    font-size: 1.3rem; } }
              .site .site-content .content-container .content-area .site-main .post:nth-child(4n+1) .entry-container .entry-content {
                padding: 1rem .5rem; }
            .site .site-content .content-container .content-area .site-main .post:nth-child(4n+1) .entry-image img {
              width: 100%;
              max-height: 20rem;
              overflow: hidden; }
            .site .site-content .content-container .content-area .site-main .post:nth-child(4n+1) .entry-image {
              margin-bottom: 20px; }
            .site .site-content .content-container .content-area .site-main .post:nth-child(4n+1) .entry-excerpt {
              margin-bottom: 20px; }
              .site .site-content .content-container .content-area .site-main .post:nth-child(4n+1) .entry-excerpt p {
                max-height: 6.2rem;
                overflow: hidden;
                text-overflow: ellipsis;
                padding: 0;
                margin: 0; }
                .site .site-content .content-container .content-area .site-main .post:nth-child(4n+1) .entry-excerpt p::first-letter {
                  font-size: 200%;
                  color: #3b60e0; }
            @media only screen and (min-width: 230px) and (max-width: 800px) {
              .site .site-content .content-container .content-area .site-main .post:nth-child(4n+1) {
                width: 100%; } }
            .site .site-content .content-container .content-area .site-main .post:nth-child(4n+1) .entry-format {
              display: table;
              right: 0;
              position: absolute;
              top: 0; }
              .site .site-content .content-container .content-area .site-main .post:nth-child(4n+1) .entry-format h2 {
                position: relative;
                text-align: center;
                vertical-align: middle;
                width: 40px;
                height: 25px;
                display: table-cell; }
                .site .site-content .content-container .content-area .site-main .post:nth-child(4n+1) .entry-format h2::before {
                  content: "";
                  position: absolute;
                  color: #fff;
                  width: 0;
                  height: 0;
                  border-top: 50px solid #3b60e0;
                  border-left: 80px solid transparent;
                  right: 0;
                  top: 0; }
                .site .site-content .content-container .content-area .site-main .post:nth-child(4n+1) .entry-format h2::after {
                  color: #fff;
                  top: 0;
                  position: absolute;
                  right: 0;
                  left: 0;
                  margin: auto; }
            .site .site-content .content-container .content-area .site-main .post:nth-child(4n+1) .entry-footer {
              display: inline-block;
              position: absolute;
              background-color: #F8DEBD;
              bottom: 15px;
              left: -10px;
              width: 100%;
              font-size: .9rem;
              padding: .4rem 0 .4rem 20px; }
              .site .site-content .content-container .content-area .site-main .post:nth-child(4n+1) .entry-footer::before {
                content: "";
                height: 100%;
                width: 10px;
                transform: skewY(43deg);
                background-color: #f3c78f;
                position: absolute;
                z-index: -1;
                top: 5px;
                left: 0; }
              .site .site-content .content-container .content-area .site-main .post:nth-child(4n+1) .entry-footer::after {
                content: "";
                position: absolute;
                width: 0;
                height: 0;
                border-top: 20px solid #fff;
                border-right: 20px solid transparent;
                right: -10px;
                bottom: -10px;
                transform: rotate(-45deg); }
              .site .site-content .content-container .content-area .site-main .post:nth-child(4n+1) .entry-footer span[class$="-links"] {
                margin-right: 15px; }
        .site .site-content .content-container .content-area .site-main .post {
          width: 100%; }
          .site .site-content .content-container .content-area .site-main .post:nth-child(4n+2) {
            background-color: #fff;
            border-top: 8px solid #FFB85F;
            display: inline-block;
            position: relative;
            margin-bottom: 1.5rem; }
            .site .site-content .content-container .content-area .site-main .post:nth-child(4n+2) .entry-title a {
              color: #FFB85F; }
            .site .site-content .content-container .content-area .site-main .post:nth-child(4n+2) .entry-container {
              padding: 0 15px 120px 15px; }
              @media only screen and (min-width: 230px) and (max-width: 800px) {
                .site .site-content .content-container .content-area .site-main .post:nth-child(4n+2) .entry-container {
                  padding: 0 15px 80px 15px; } }
              .site .site-content .content-container .content-area .site-main .post:nth-child(4n+2) .entry-container .entry-header .entry-title {
                text-align: right;
                padding-right: 63px; }
                @media only screen and (min-width: 230px) and (max-width: 800px) {
                  .site .site-content .content-container .content-area .site-main .post:nth-child(4n+2) .entry-container .entry-header .entry-title {
                    text-align: left;
                    font-size: 1.3rem; } }
              .site .site-content .content-container .content-area .site-main .post:nth-child(4n+2) .entry-container .entry-content {
                padding: 1rem .5rem; }
            .site .site-content .content-container .content-area .site-main .post:nth-child(4n+2) .entry-image img {
              width: 100%;
              max-height: 20rem;
              overflow: hidden; }
            .site .site-content .content-container .content-area .site-main .post:nth-child(4n+2) .entry-image {
              margin-bottom: 20px; }
            .site .site-content .content-container .content-area .site-main .post:nth-child(4n+2) .entry-excerpt {
              margin-bottom: 20px; }
              .site .site-content .content-container .content-area .site-main .post:nth-child(4n+2) .entry-excerpt p {
                max-height: 6.2rem;
                overflow: hidden;
                text-overflow: ellipsis;
                padding: 0;
                margin: 0; }
                .site .site-content .content-container .content-area .site-main .post:nth-child(4n+2) .entry-excerpt p::first-letter {
                  font-size: 200%;
                  color: #FFB85F; }
            @media only screen and (min-width: 230px) and (max-width: 800px) {
              .site .site-content .content-container .content-area .site-main .post:nth-child(4n+2) {
                width: 100%; } }
            .site .site-content .content-container .content-area .site-main .post:nth-child(4n+2) .entry-format {
              display: table;
              right: 0;
              position: absolute;
              top: 0; }
              .site .site-content .content-container .content-area .site-main .post:nth-child(4n+2) .entry-format h2 {
                position: relative;
                text-align: center;
                vertical-align: middle;
                width: 40px;
                height: 25px;
                display: table-cell; }
                .site .site-content .content-container .content-area .site-main .post:nth-child(4n+2) .entry-format h2::before {
                  content: "";
                  position: absolute;
                  color: #fff;
                  width: 0;
                  height: 0;
                  border-top: 50px solid #FFB85F;
                  border-left: 80px solid transparent;
                  right: 0;
                  top: 0; }
                .site .site-content .content-container .content-area .site-main .post:nth-child(4n+2) .entry-format h2::after {
                  color: #fff;
                  top: 0;
                  position: absolute;
                  right: 0;
                  left: 0;
                  margin: auto; }
            .site .site-content .content-container .content-area .site-main .post:nth-child(4n+2) .entry-footer {
              display: inline-block;
              position: absolute;
              background-color: #F8DEBD;
              bottom: 15px;
              left: -10px;
              width: 100%;
              font-size: .9rem;
              padding: .4rem 0 .4rem 20px; }
              .site .site-content .content-container .content-area .site-main .post:nth-child(4n+2) .entry-footer::before {
                content: "";
                height: 100%;
                width: 10px;
                transform: skewY(43deg);
                background-color: #f3c78f;
                position: absolute;
                z-index: -1;
                top: 5px;
                left: 0; }
              .site .site-content .content-container .content-area .site-main .post:nth-child(4n+2) .entry-footer::after {
                content: "";
                position: absolute;
                width: 0;
                height: 0;
                border-top: 20px solid #fff;
                border-right: 20px solid transparent;
                right: -10px;
                bottom: -10px;
                transform: rotate(-45deg); }
              .site .site-content .content-container .content-area .site-main .post:nth-child(4n+2) .entry-footer span[class$="-links"] {
                margin-right: 15px; }
        .site .site-content .content-container .content-area .site-main .post {
          width: 100%; }
          .site .site-content .content-container .content-area .site-main .post:nth-child(4n+3) {
            background-color: #fff;
            border-top: 8px solid #00AAA0;
            display: inline-block;
            position: relative;
            margin-bottom: 1.5rem; }
            .site .site-content .content-container .content-area .site-main .post:nth-child(4n+3) .entry-title a {
              color: #00AAA0; }
            .site .site-content .content-container .content-area .site-main .post:nth-child(4n+3) .entry-container {
              padding: 0 15px 120px 15px; }
              @media only screen and (min-width: 230px) and (max-width: 800px) {
                .site .site-content .content-container .content-area .site-main .post:nth-child(4n+3) .entry-container {
                  padding: 0 15px 80px 15px; } }
              .site .site-content .content-container .content-area .site-main .post:nth-child(4n+3) .entry-container .entry-header .entry-title {
                text-align: right;
                padding-right: 63px; }
                @media only screen and (min-width: 230px) and (max-width: 800px) {
                  .site .site-content .content-container .content-area .site-main .post:nth-child(4n+3) .entry-container .entry-header .entry-title {
                    text-align: left;
                    font-size: 1.3rem; } }
              .site .site-content .content-container .content-area .site-main .post:nth-child(4n+3) .entry-container .entry-content {
                padding: 1rem .5rem; }
            .site .site-content .content-container .content-area .site-main .post:nth-child(4n+3) .entry-image img {
              width: 100%;
              max-height: 20rem;
              overflow: hidden; }
            .site .site-content .content-container .content-area .site-main .post:nth-child(4n+3) .entry-image {
              margin-bottom: 20px; }
            .site .site-content .content-container .content-area .site-main .post:nth-child(4n+3) .entry-excerpt {
              margin-bottom: 20px; }
              .site .site-content .content-container .content-area .site-main .post:nth-child(4n+3) .entry-excerpt p {
                max-height: 6.2rem;
                overflow: hidden;
                text-overflow: ellipsis;
                padding: 0;
                margin: 0; }
                .site .site-content .content-container .content-area .site-main .post:nth-child(4n+3) .entry-excerpt p::first-letter {
                  font-size: 200%;
                  color: #00AAA0; }
            @media only screen and (min-width: 230px) and (max-width: 800px) {
              .site .site-content .content-container .content-area .site-main .post:nth-child(4n+3) {
                width: 100%; } }
            .site .site-content .content-container .content-area .site-main .post:nth-child(4n+3) .entry-format {
              display: table;
              right: 0;
              position: absolute;
              top: 0; }
              .site .site-content .content-container .content-area .site-main .post:nth-child(4n+3) .entry-format h2 {
                position: relative;
                text-align: center;
                vertical-align: middle;
                width: 40px;
                height: 25px;
                display: table-cell; }
                .site .site-content .content-container .content-area .site-main .post:nth-child(4n+3) .entry-format h2::before {
                  content: "";
                  position: absolute;
                  color: #fff;
                  width: 0;
                  height: 0;
                  border-top: 50px solid #00AAA0;
                  border-left: 80px solid transparent;
                  right: 0;
                  top: 0; }
                .site .site-content .content-container .content-area .site-main .post:nth-child(4n+3) .entry-format h2::after {
                  color: #fff;
                  top: 0;
                  position: absolute;
                  right: 0;
                  left: 0;
                  margin: auto; }
            .site .site-content .content-container .content-area .site-main .post:nth-child(4n+3) .entry-footer {
              display: inline-block;
              position: absolute;
              background-color: #F8DEBD;
              bottom: 15px;
              left: -10px;
              width: 100%;
              font-size: .9rem;
              padding: .4rem 0 .4rem 20px; }
              .site .site-content .content-container .content-area .site-main .post:nth-child(4n+3) .entry-footer::before {
                content: "";
                height: 100%;
                width: 10px;
                transform: skewY(43deg);
                background-color: #f3c78f;
                position: absolute;
                z-index: -1;
                top: 5px;
                left: 0; }
              .site .site-content .content-container .content-area .site-main .post:nth-child(4n+3) .entry-footer::after {
                content: "";
                position: absolute;
                width: 0;
                height: 0;
                border-top: 20px solid #fff;
                border-right: 20px solid transparent;
                right: -10px;
                bottom: -10px;
                transform: rotate(-45deg); }
              .site .site-content .content-container .content-area .site-main .post:nth-child(4n+3) .entry-footer span[class$="-links"] {
                margin-right: 15px; }
        .site .site-content .content-container .content-area .site-main .post {
          width: 100%; }
          .site .site-content .content-container .content-area .site-main .post:nth-child(4n+4) {
            background-color: #fff;
            border-top: 8px solid #7B8D8E;
            display: inline-block;
            position: relative;
            margin-bottom: 1.5rem; }
            .site .site-content .content-container .content-area .site-main .post:nth-child(4n+4) .entry-title a {
              color: #7B8D8E; }
            .site .site-content .content-container .content-area .site-main .post:nth-child(4n+4) .entry-container {
              padding: 0 15px 120px 15px; }
              @media only screen and (min-width: 230px) and (max-width: 800px) {
                .site .site-content .content-container .content-area .site-main .post:nth-child(4n+4) .entry-container {
                  padding: 0 15px 80px 15px; } }
              .site .site-content .content-container .content-area .site-main .post:nth-child(4n+4) .entry-container .entry-header .entry-title {
                text-align: right;
                padding-right: 63px; }
                @media only screen and (min-width: 230px) and (max-width: 800px) {
                  .site .site-content .content-container .content-area .site-main .post:nth-child(4n+4) .entry-container .entry-header .entry-title {
                    text-align: left;
                    font-size: 1.3rem; } }
              .site .site-content .content-container .content-area .site-main .post:nth-child(4n+4) .entry-container .entry-content {
                padding: 1rem .5rem; }
            .site .site-content .content-container .content-area .site-main .post:nth-child(4n+4) .entry-image img {
              width: 100%;
              max-height: 20rem;
              overflow: hidden; }
            .site .site-content .content-container .content-area .site-main .post:nth-child(4n+4) .entry-image {
              margin-bottom: 20px; }
            .site .site-content .content-container .content-area .site-main .post:nth-child(4n+4) .entry-excerpt {
              margin-bottom: 20px; }
              .site .site-content .content-container .content-area .site-main .post:nth-child(4n+4) .entry-excerpt p {
                max-height: 6.2rem;
                overflow: hidden;
                text-overflow: ellipsis;
                padding: 0;
                margin: 0; }
                .site .site-content .content-container .content-area .site-main .post:nth-child(4n+4) .entry-excerpt p::first-letter {
                  font-size: 200%;
                  color: #7B8D8E; }
            @media only screen and (min-width: 230px) and (max-width: 800px) {
              .site .site-content .content-container .content-area .site-main .post:nth-child(4n+4) {
                width: 100%; } }
            .site .site-content .content-container .content-area .site-main .post:nth-child(4n+4) .entry-format {
              display: table;
              right: 0;
              position: absolute;
              top: 0; }
              .site .site-content .content-container .content-area .site-main .post:nth-child(4n+4) .entry-format h2 {
                position: relative;
                text-align: center;
                vertical-align: middle;
                width: 40px;
                height: 25px;
                display: table-cell; }
                .site .site-content .content-container .content-area .site-main .post:nth-child(4n+4) .entry-format h2::before {
                  content: "";
                  position: absolute;
                  color: #fff;
                  width: 0;
                  height: 0;
                  border-top: 50px solid #7B8D8E;
                  border-left: 80px solid transparent;
                  right: 0;
                  top: 0; }
                .site .site-content .content-container .content-area .site-main .post:nth-child(4n+4) .entry-format h2::after {
                  color: #fff;
                  top: 0;
                  position: absolute;
                  right: 0;
                  left: 0;
                  margin: auto; }
            .site .site-content .content-container .content-area .site-main .post:nth-child(4n+4) .entry-footer {
              display: inline-block;
              position: absolute;
              background-color: #F8DEBD;
              bottom: 15px;
              left: -10px;
              width: 100%;
              font-size: .9rem;
              padding: .4rem 0 .4rem 20px; }
              .site .site-content .content-container .content-area .site-main .post:nth-child(4n+4) .entry-footer::before {
                content: "";
                height: 100%;
                width: 10px;
                transform: skewY(43deg);
                background-color: #f3c78f;
                position: absolute;
                z-index: -1;
                top: 5px;
                left: 0; }
              .site .site-content .content-container .content-area .site-main .post:nth-child(4n+4) .entry-footer::after {
                content: "";
                position: absolute;
                width: 0;
                height: 0;
                border-top: 20px solid #fff;
                border-right: 20px solid transparent;
                right: -10px;
                bottom: -10px;
                transform: rotate(-45deg); }
              .site .site-content .content-container .content-area .site-main .post:nth-child(4n+4) .entry-footer span[class$="-links"] {
                margin-right: 15px; }
      @media only screen and (min-width: 230px) and (max-width: 800px) {
        .site .site-content .content-container .content-area {
          width: 100%; } }
    .site .site-content .content-container .widget-area {
      display: inline-block;
      overflow: hidden;
      width: 35%;
      padding: 0 0 0 1.5rem; }
      @media only screen and (min-width: 230px) and (max-width: 800px) {
        .site .site-content .content-container .widget-area {
          width: 100%;
          padding: 0; } }
  .site .site-footer {
    clear: both;
    display: inline-block;
    width: 100%; }
  .site .page-title {
    margin-top: 0;
    color: #fff; }
  @media only screen and (min-width: 230px) and (max-width: 800px) {
    .site {
      width: 98%;
      margin-top: 0; } }

/*--------------------------------------------------------------
## Footer
--------------------------------------------------------------*/
.site-footer .footer-widgets {
  display: flex; }

.site-footer .site-info {
  text-align: left; }

.site-footer .textwidget {
  width: 100%;
  padding: 10px;
  color: #fff; }

/*--------------------------------------------------------------
## Header
--------------------------------------------------------------*/
.site-header {
  clear: both;
  width: 100%;
  padding: 0 5%;
  color: #fff; }
  @media only screen and (min-width: 230px) and (max-width: 800px) {
    .site-header {
      padding: 0; } }
  .site-header a {
    color: #fff; }
  .site-header .site-branding {
    text-align: center;
    width: 80%;
    margin: 0 auto;
    padding-top: 1rem; }
    @media only screen and (min-width: 230px) and (max-width: 800px) {
      .site-header .site-branding {
        width: 100%; } }
  @media only screen and (min-width: 230px) and (max-width: 800px) {
    .site-header {
      display: block; } }

.about {
  margin: 20px auto; }
  .about img {
    height: 15rem; }
  .about .about-description {
    background-color: #fff;
    border: 1px dashed #fff;
    text-align: center;
    width: 100%;
    padding: 10px 0; }
    .about .about-description .about-title {
      position: relative; }
    .about .about-description .read-more {
      display: inline-block;
      -webkit-appearance: initial;
      position: relative; }

/*--------------------------------------------------------------
## Sidebar
--------------------------------------------------------------*/
.widget-area .widget-related-post li {
  display: inline-block;
  margin-bottom: 15px; }
  .widget-area .widget-related-post li img {
    float: left;
    height: 50px;
    margin-right: 5px;
    width: 50px; }

.widget-area .widget_search label {
  display: block;
  width: 100%; }

.widget-area .widget_search input[type='search'] {
  width: 100%; }

.widget-area .widget_tag_cloud a {
  padding: 2px;
  border: 1px solid #6b7b8d;
  border-radius: 5px;
  white-space: nowrap;
  line-height: 2; }

/*--------------------------------------------------------------
## Single
--------------------------------------------------------------*/
